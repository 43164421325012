// sass-lint:disable indentation, no-duplicate-properties

@font-face {
  font-family: "LatoThinItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoThin";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLightItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LatoItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBoldItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlackItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlack";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/LatoBlack.ttf") format("truetype");
}

@font-face {
  font-family: "deascuola";
  font-weight: normal;
  src: url("../fonts/deascuola.eot");
  src: url("../fonts/deascuola.eot?#iefix") format("embedded-opentype"), url("../fonts/deascuola.woff") format("woff"),
    url("../fonts/deascuola.ttf") format("truetype"), url("../fonts/deascuola.svg#deascuola") format("svg");
}

@font-face {
  font-family: "Figtree";
  font-style: normal;
  src: url("../fonts/Figtree.ttf");
}

@font-face {
  font-family: "LatoRegular";
  font-style: normal;
  src: url("../fonts/Lato-Regular.ttf");
}

// sass-lint:enable indentation, no-duplicate-properties
